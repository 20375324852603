<template>
  <BaseContainer>
    <PipelineSettings />
  </BaseContainer>
</template>

<script>
import PipelineSettings from '@components/Settings/PipelineSettings'

export default {
  components: {
    PipelineSettings
  }
}
</script>

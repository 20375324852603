<template>
  <div>
    <button
      class="hover:text-red-400 duration-150 ease-out"
      @click.stop="modalOpen = true"
    >
      <Icon
        icon="Bin"
        view-box="0 0 24 24"
        class="w-6 h-6 ml-1 fill-none"
      >
        <Bin />
      </Icon>
    </button>

    <Modal
      :open="modalOpen"
      @close="cancelDeleteStageModal()"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Are you sure you want to delete the
            <p>
              <span class="underline">{{ stageToBeDeleted.name }}</span> stage?
            </p>
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="cancelDeleteStageModal()"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div class="p-6">
        Candidate evaluation and candidate move activities will be permanently deleted. All other activities will be assigned to the targeted recruitment stage, which you can select below:

        <label class="w-full block mt-6">
          <select
            v-model="stageToBeMovedTo"
            class="form-select block w-full mt-1"
          >
            <option
              :value="null"
              disabled
            >Select target stage</option>
            <option
              v-for="(stage, index) in stages"
              :key="index"
              :value="stage"
            >{{ stage.name }}</option>
          </select>
        </label>

        <div class="flex items-center justify-end space-x-4 mt-6">
          <BaseButton
            variant="ghost"
            @click.stop="cancelDeleteStageModal()"
          >
            Cancel
          </BaseButton>
          <BaseButton
            :disabled="!stageToBeMovedTo"
            :loading="deletingStage"
            @click="deleteStage()"
          >
            Delete
          </BaseButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import Bin from '@components/Icons/Bin'

import { mapGetters } from 'vuex'

export default {
  components: {
    Modal,
    Icon,
    Plus,
    Bin
  },

  props: {
    stageToBeDeleted: {
      type: Object,
      default: null
    },
    stages: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false,
      stageToBeMovedTo: null,
      deletingStage: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'organisations/id'
    })
  },

  methods: {
    cancelDeleteStageModal() {
      this.modalOpen = false
      this.stageToBeMovedTo = null
    },

    deleteStage() {
      this.$store.dispatch('organisations/deletePipelineStage', {
        original_slug: this.stageToBeDeleted.slug,
        new_slug: this.stageToBeMovedTo.slug
      }).then(pipeline => {
        this.modalOpen = false
        this.$emit('stageDeleted', pipeline)
      })
    }
  }
}
</script>

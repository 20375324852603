<template>
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full divide-y divide-dashed divide-gray-200 table-fixed">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="w-1/3 pl-12 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stage Name
                  </th>
                  <th
                    scope="col"
                    class="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stage type
                  </th>
                  <th
                    scope="col"
                    class="w-1/3 relative px-6 py-3"
                  >
                  </th>
                </tr>
              </thead>

              <PipelineDefaultStageTable :stages="fixedStages.slice(0, 1)" />

              <draggable
                v-model="pipeline"
                ghost-class="drag"
                :animation="200"
                tag="tbody"
                @change="updatePipeline"
              >
                <tr
                  v-for="(stage, index) in pipeline"
                  :key="index"
                  :class="{'bg-gray-100': activeItem === index}"
                  @click="toggleAccordion(index)"
                >
                  <template v-if="activeItem === index">
                    <td class="hidden md:table-cell pr-3 pl-6 py-4 whitespace-nowrap text-sm">
                      <div class="flex">
                        <span class="block w-6 h-6"></span>

                        <label class="block w-full">
                          <span class="text-gray-700">Stage name</span>
                          <input
                            v-model="stage.name"
                            type="text"
                            class="form-input mt-1 block w-full"
                            placeholder=""
                          >
                        </label>
                      </div>
                    </td>

                    <td
                      class="hidden md:table-cell pr-3 pl-6 py-4 whitespace-nowrap text-sm"
                      colspan="2"
                    >
                      <div class="flex justify-between items-end space-x-6">
                        <label class="w-full block">
                          <span class="text-gray-700">Stage type</span>
                          <select
                            v-model="stage.type"
                            class="form-select block w-full mt-1"
                          >
                            <option
                              v-for="(type, index) in stageTypes"
                              :key="index"
                            >{{ type }}</option>
                          </select>
                        </label>
                        <div class="flex items-center space-x-4">
                          <BaseButton
                            variant="ghost"
                            @click.stop="handleCancelEditStage(stage)"
                          >
                            Cancel
                          </BaseButton>
                          <BaseButton
                            :loading="loading"
                            @click="updatePipeline"
                          >
                            Save
                          </BaseButton>
                        </div>
                      </div>
                    </td>

                    <td
                      class="md:hidden pr-3 pl-6 py-4 whitespace-nowrap text-sm"
                      colspan="3"
                    >
                      <label class="block">
                        <span class="text-gray-700">Stage name</span>
                        <input
                          v-model="stage.name"
                          type="text"
                          class="form-input mt-1 block w-full"
                          placeholder=""
                        >
                      </label>
                      <label class="w-full block mt-4">
                        <span class="text-gray-700">Stage type</span>
                        <select
                          v-model="stage.type"
                          class="form-select block w-full mt-1"
                        >
                          <option
                            v-for="(type, index) in stageTypes"
                            :key="index"
                          >{{ type }}</option>
                        </select>
                      </label>
                      <div class="flex items-center space-x-4 mt-4">
                        <BaseButton
                          variant="ghost"
                          @click.stop="handleCancelEditStage(stage)"
                        >
                          Cancel
                        </BaseButton>
                        <BaseButton
                          :loading="loading"
                          @click="updatePipeline"
                        >
                          Save
                        </BaseButton>
                      </div>
                    </td>
                  </template>

                  <template v-else>
                    <td class="px-3 py-4 whitespace-nowrap text-sm cursor-pointer">
                      <div class="flex items-center">
                        <span class="text-gray-500 cursor-grab">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                          </svg>
                        </span>
                        <span class="ml-3 text-base">
                          {{ stage.name }}
                        </span>
                        <div>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600 cursor-pointer">
                      {{ stage.type }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        class="flex items-center justify-end space-x-4 text-gray-600"
                      >
                        <button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>

                        <PipelineStageDeleteButton
                          :stage-to-be-deleted="stage"
                          :stages="targetStages(stage)"
                          @stageDeleted="pipeline = $event"
                        />
                      </div>
                    </td>
                  </template>
                </tr>
              </draggable>

              <PipelineDefaultStageTable :stages="fixedStages.slice(1, 2)" />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import PipelineStageDeleteButton from '@components/PipelineStageDeleteButton'
import PipelineDefaultStageTable from '@components/PipelineDefaultStageTable'

import { mapGetters } from 'vuex'

export default {
  components: {
    draggable,
    PipelineStageDeleteButton,
    PipelineDefaultStageTable
  },

  props: {
    newStage: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      dragging: false,
      activeItem: null,
      pipeline: [],
      errors: [],

      stageTypes: [
        'Screen',
        'Assessment',
        'Interview',
        'Shortlisted',
        'Offer'
      ]
    }
  },

  computed: {
    ...mapGetters({
      storePipeline: 'organisations/pipeline',
      editablePipeline: 'organisations/editablePipeline'
    }),

    fixedStages() {
      return this.storePipeline.filter(stage => stage.fixed)
    }
  },

  watch: {
    newStage(val) {
      if (val) {
        this.pipeline.unshift({ name: '', type: 'Screen', position: 0, slug: null })
        this.activeItem = 0
      }
    }
  },

  mounted() {
    this.pipeline = [...this.editablePipeline]
  },

  methods: {
    toggleAccordion(index) {
      this.activeItem = index
    },

    handleCancelEditStage(stage) {
      this.activeItem = null

      if (!stage.slug) {
        // If they havent saved it, remove it from the array
        this.pipeline.shift()
      }

      this.$emit('cancelNewstage')
    },

    targetStages(selectedStage) {
      return this.storePipeline.filter(stage => stage.name !== selectedStage.name)
    },

    updatePipeline() {
      this.loading = true

      this.$store.dispatch('organisations/updatePipeline', this.pipeline)
        .then(pipeline => {
          this.activeItem = null
          this.$emit('cancelNewstage')
          this.loading = false
        })
        .catch(error => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors
          } else {
            console.error(error.message)
            this.errors = ['Sorry, an error occured']
          }
          this.loading = false
        })
    }
  }
}
</script>

<template>
  <tbody>
    <tr
      v-for="(stage, index) in stages"
      :key="`default-state-${index}`"
    >
      <td class="px-3 py-4 whitespace-nowrap text-sm">
        <div class="flex items-center text-gray-500">
          <span class="h-6 w-6"></span>
          <span class="ml-3 text-base">
            {{ stage.name }}
          </span>
          <div>
          </div>
        </div>
      </td>

      <td
        class="px-6 py-4 whitespace-nowrap text-sm"
        colspan="2"
      >
        <div class="flex items-center text-gray-500">
          <span>
            {{ stage.type }}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    stages: {
      type: Array,
      default: null
    }
  }
}
</script>

<template>
  <div>
    <SettingsNavigation />

    <div
      v-if="hasOrganisationLoaded"
      class="w-full max-w-screen-xl mx-auto sm:flex sm:justify-between px-6"
    >
      <div class="w-full bg-white shadow rounded py-6">
        <div class="px-6">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <span
                class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white flex-shrink-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </span>
              <h2 class="text-lg">
                Pipeline Stages
              </h2>
            </div>
            <BaseButton
              :disabled="addNewStage"
              @click="addNewStage = true"
            >
              Add stage
            </BaseButton>
          </div>

          <ul class="list-disc list-inside text-sm mt-6 space-y-2">
            <li>
              Set up your recruitment pipeline to match your hiring process.
            </li>
            <li>
              Re-arrange your pipeline stages by dragging and dropping.
            </li>
            <li>
              Click on each stage to rename or delete them.
            </li>
          </ul>
        </div>

        <PipelineStages
          class="mt-8"
          :new-stage="addNewStage"
          @cancelNewstage="addNewStage = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import PipelineStages from '@components/PipelineStages'

import { mapGetters } from 'vuex'

export default {
  components: {
    SettingsNavigation,
    PipelineStages
  },

  data() {
    return {
      addNewStage: false
    }
  },

  computed: {
    ...mapGetters({
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded'
    })
  }
}
</script>
